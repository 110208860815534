import { gql } from "@apollo/client";

export const Get_A_User_By_Id_Query = gql`
query GetAUserById($getAUserByIdId: ID!) {
    getAUserById(id: $getAUserByIdId) {
      id
      email
      firstname
      lastname
      mobile
      password
      emailVerified
      mobileVerified
      enabled2FA
      role
      addr
      pvtKey
    }
  }
`
