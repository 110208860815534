import React from 'react';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as Twitter } from '../assets/twitter.svg';
import { ReactComponent as Facebook } from '../assets/facebook.svg';
import { ReactComponent as Instagram } from '../assets/instagram.svg';
import { NavLink } from 'react-router-dom';


const Footer = () => {
  return (
      <footer className='footer'>
            <div className='footer-item-1'>
                <NavLink to='/' className='footer-item-1_logo'>
                    <Logo />
                </NavLink>
                <p className='footer-item-1_copyright'>© 2022 - Aeropaye. All rights reserved</p>
                <p className='footer-item-1_about'>Refund And Payment Airline Infrastructure Built On The Blockchain</p>
                <div className='flex footer-item-1_logo-icons'>
                    <NavLink to='/'>
                        <Twitter />
                    </NavLink>
                    <NavLink to='/'>
                        <Facebook />
                    </NavLink>
                    <NavLink to='/'>
                        <Instagram />
                    </NavLink>
                </div>
            </div>

            <div className="footer-item-2">
                <p>Company</p>
                <ul className='flex flex-col'>
                    <a href='#faq' className='links'>FAQ</a>
                    <NavLink to='/' className='links'>Contact Us</NavLink>
                    <NavLink to='/' className='links'>Aero News</NavLink>
                    <NavLink to='/' className='links'>Developers</NavLink>
                </ul>
            </div>

            <div className="footer-item-2">
                <p>Quick Links</p>
                <ul className='flex flex-col'>
                    <NavLink to='/' className='links'>Book a flight</NavLink>
                    <NavLink to='/' className='links'>Claim a fund</NavLink>
                    <NavLink to='/' className='links'>Buy Aeropaye token</NavLink>
                </ul>
            </div>

            <div className="footer-item-2">
                <p>Legal</p>
                <ul className='flex flex-col'>
                    <NavLink to='/' className='links'>Privacy policy</NavLink>
                    <NavLink to='/' className='links'>Terms and conditions</NavLink>
                </ul>
            </div>
      </footer>
  )
};

export default Footer;
