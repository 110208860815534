import { gql } from "@apollo/client";

export const SIGNUP_INDIVIDUAL_MUTATION = gql`
	mutation SignUpIndividual(
		$email: String!
		$password: String!
		$firstname: String!
		$lastname: String!
		$mobile: String!
	) {
		signup(
			email: $email
			password: $password
			firstname: $firstname
			lastname: $lastname
			mobile: $mobile
		) {
			token
			user {
				id
				mobile
			}
		}
	}
`;