import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./Home";

import AirlineLogin from "./components/login/AirlineLogin";
import UserLogin from "./components/login/UserLogin";

import ForgotPassword from "./components/ForgotPassword";

import AirlineSignUp from "./components/signup/AirlineSignUp";
import UserSignUp from "./components/signup/UserSignUp";

import TwoFA from "./components/TwoFA";
import { Helmet } from "react-helmet";

// Toasts Notification
import "react-toastify/dist/ReactToastify.css";

function App() {
	return (
		<>
			<Router>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Aeropaye Blockchain smart flight refund engine</title>
					<link rel="canonical" href="https://aeropaye.com" />
					<meta
						name="description"
						content="Aeropaye Blockchain smart flight refund and payments engine."
					/>
				</Helmet>
				<Routes>
					<Route path="/" element={<LandingPage />} />
					{/* <Route path="/user-login" element={<UserLogin />} /> */}
					{/* <Route path="/airline-login" element={<AirlineLogin />} /> */}
					{/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
					{/* <Route path="/airline-signup" element={<AirlineSignUp />} /> */}
					{/* <Route path="/user-signup" element={<UserSignUp />} /> */}
					{/* <Route path="/2FA" element={<TwoFA />} /> */}
				</Routes>
			</Router>
			{/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
      /> */}
		</>
	);
}

export default App;
