import React from 'react';
import { ReactComponent as ArrowRight } from "../assets/arrow-right.svg";
import { ReactComponent as BlueIcon } from "../assets/blue-icon.svg";
import BenefitImage from "../assets/benefit-img.svg";
import { ReactComponent as Tick } from '../assets/tick-circle.svg';

const Benefit = () => {
  return (
      <>
        <div className="hero-features flex sm:justify-center justify-start items-center flex-wrap">
        <div className="flex items-center hero-features-item">
            <Tick className='hero-features-item_icon' />
            <p>Tokenized ticketing system</p>
        </div>
        <div className="flex items-center hero-features-item">
            <Tick className='hero-features-item_icon' />
            <p>Autonomous refund engine</p>
        </div>
        <div className="flex items-center hero-features-item">
            <Tick className='hero-features-item_icon' />
            <p>Blockchain smart contract secured</p>
        </div>
        <div className="flex items-center hero-features-item">
            <Tick className='hero-features-item_icon' />
            <p>Access to exclusive discounts</p>
        </div>
    </div> 


        <div className='container benefit-contain flex md:flex-row flex-col-reverse items-center md:justify-evenly justify-center'>
        <div className="benefit">
            <p className='benefit-subtitle'>BENEFITS</p>
            <p className='benefit-title'>Why Aeropaye?</p>

            <div className="benefit-list">
                <div className="flex benefit-list-items">
                    <BlueIcon />
                    <p>
                        Customers Recover funds from cancelled or delayed flight in record time without documentation problem.
                    </p>
                </div>

                <div className="flex benefit-list-items">
                    <BlueIcon />
                    <p>
                        Airline maintain great customer support and integrity with swift reimbursement at the speed of the internent.
                    </p>
                </div>

                <div className="flex benefit-list-items">
                    <BlueIcon />
                    <p>
                        Efficient, fast and trustless payment infrastructure for both airline and customers.
                    </p>
                </div>

                <div className="flex benefit-list-items">
                    <BlueIcon />
                    <p>
                        Open API easily integratable on any website and platform for airline transactions.
                    </p>
                </div>

                <button type="button" className='benefit-button flex items-center'>
                    Claim a Refund <ArrowRight className='ml-[10px]' />
                </button>

            </div>
        </div>

        <div className='mx-4 md:mx-0'>
            <img src={BenefitImage} alt='benefit-img' className='object-scale-down' />
        </div>
      </div>

      </>
     
  );
};

export default Benefit;
