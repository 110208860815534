import React, { useState } from 'react';
import { ReactComponent as Card } from '../assets/card.svg';
import { ReactComponent as Plane } from '../assets/plane.svg';
import { ReactComponent as Email } from '../assets/email.svg';
import { ReactComponent as Ticket } from '../assets/ticket-id.svg';
import LoginModal from './LoginModal';
import Booking from './Booking';

const HeroDetail = () => {
    const [activeState, setActiveState] = useState(false);
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const showRefund = () => {
        setActiveState(!activeState)
    }

    const showBooking = () => {
        setActiveState(true)
    }

  return (
      <div className='hero-details'>
        <div className="flex items-center">
            <button type='button' 
            className={`flex items-center tab ${activeState? '' : 'active-tab'}`}
            onClick={() => {setActiveState(!activeState); showRefund()}}>
                <Card className='svgs' />
                <span> Claim a refund</span>
            </button>

            <button type='button' 
            className={`flex items-center tab ${activeState? 'active-tab' : ''}`}
            onClick={() => {setActiveState(true); showBooking()}}>
                <Plane className='svgs' /> 
                <span> Book a flight</span>
            </button>
        </div>
        
        {
            activeState ? <Booking open={open} onCloseModal={onCloseModal} onOpenModal={onOpenModal} />  :
            <div className='refund-container flex items-center transition duration-500 ease-in-out'>
                <label className='mr-[8px] flex refund-container_ticket-id items-center'>
                    <span className='flex items-center w-auto'>
                    <Ticket className='mr-[8px]' />Ticketless ID:
                    </span>
                    <input type="text" placeholder='XXXXXX' />
                </label>
                <label className='mr-[8px] flex refund-container_email'>
                    <span className='flex items-center'>
                        <Email className='mr-[8px]' />Email:
                    </span>
                    <input type="email" placeholder='example@gmail.com' />
                </label>
                <button className='refund-container_button'
                onClick={onOpenModal}
                >
                    Get Refund
                </button>
            </div>
        }
        <LoginModal open={open} onCloseModal={onCloseModal} />
      </div>
  )
};

export default HeroDetail;
