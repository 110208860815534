import React, { useState, useEffect } from 'react';
import Nav from './components/Nav';
import MobileNav from './components/mobile/Nav';
import Header from './components/Header';
import Benefit from './components/Benefit';
import Features from './components/Features';
import Faqs from './components/Faqs';
import Newsletter from './components/Newsletter';
import Footer from './components/Footer';

const LandingPage = () => {
  const [openNav, setOpenNav] = useState(false);
    const showNav = () => {
        setOpenNav(!openNav); 
    }
  const [mobileView, setMobileView] = useState(window.matchMedia("(max-width:800px)").matches);
    useEffect(() => {
        window.addEventListener("resize", () => {
        setMobileView(window.matchMedia("(max-width:800px)").matches);
        });
    });
  return (
      <>
      {
        mobileView ? <MobileNav showNav={showNav} openNav={openNav} /> : <Nav />
      }
      <div className={openNav ? 'blur-sm' : ''}>
        <Header />
        <Benefit />
        <Features />
        <Faqs />
        <Newsletter />
        <Footer />
      </div>
      </>
  )
};

export default LandingPage;
