import React from 'react';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { NavLink } from 'react-router-dom';

const Nav = () => {
  return (
    <nav className="flex justify-around items-center sticky z-10 top-0 w-full">
      <NavLink to="/">
        <Logo className="w-auto" />
      </NavLink>

      <ul className="flex">
        <a href="#faq" className="mr-[32px] nav-item">
          FAQ
        </a>
        <NavLink to="/" className="mr-[32px] nav-item">
          Contact Us
        </NavLink>
        <NavLink to="/" className="mr-[32px] nav-item">
          Aero News
        </NavLink>
      </ul>

      <ul className="flex items-center">
        <a
          href="https://dashboard.aeropaye.com/login"
          className="mr-[24px] nav-item"
        >
          Log In
        </a>
        <a
          href="http://dashboard.aeropaye.com/signup"
          className="btn-nav_refund"
        >
          Get started
        </a>
      </ul>
    </nav>
  );
};

export default Nav;
