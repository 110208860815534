import { gql } from "@apollo/client";

export const SIGNUP_AIRLINE_MUTATION = gql`
	mutation SignUpAirline(
		$email: String!
		$password: String!
		$airlineName: String!
		$mobile: String!
	) {
		airlineSignup(
			email: $email
			password: $password
			airlineName: $airlineName
			mobile: $mobile
		) {
			token
			airline {
				id
			}
		}
	}
`;
