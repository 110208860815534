import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { ReactComponent as Profile } from "../assets/Profile.svg";
import { ReactComponent as Password } from "../assets/password.svg";
import { ReactComponent as Hide } from "../assets/Hide.svg";
import { ReactComponent as ShowIcon } from "../assets/showIcon.svg";
import { ReactComponent as Recaptcha } from "../assets/recapcha.svg";
import { NavLink, useNavigate } from "react-router-dom";

const LoginModal = ({ open, onCloseModal }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [focus, setFocus] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email === "") {
            setError("Input Email");
        } else if (password === "") {
            setError("Input Password");
        } else {
            window.location.replace("http://localhost:3001");
        }
    };

    return (
        <div>
            <Modal open={open} onClose={onCloseModal} center>
                <div className="loginModal-container">
                    <p className="title">Sign into your account</p>
                    {error && <p className="error-message">{error}</p>}

                    <div className={`${error ? "error-line" : "line"}`}></div>

                    <form onSubmit={handleSubmit}>
                        <div className="loginModal-container_email">
                            <p className="label">Email or Phone</p>
                            <label
                                className={`flex items-center ${
                                    focus === "email-input" ? "clicked" : ""
                                }`}
                            >
                                <span className="flex items-center w-auto">
                                    <Profile className="mr-[8px]" />
                                </span>
                                <input
                                    type="text"
                                    value={email}
                                    placeholder="Email address or phone number"
                                    onKeyPress={() => setFocus("email-input")}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </label>
                        </div>

                        <div className="loginModal-container_email">
                            <p className="label">Password</p>
                            <label
                                className={`flex items-center ${
                                    focus === "password-input" ? "clicked" : ""
                                }`}
                            >
                                <span className="flex items-center w-auto">
                                    <Password className="mr-[8px]" />
                                </span>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    placeholder="Password"
                                    onKeyPress={() =>
                                        setFocus("password-input")
                                    }
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                                <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setShowPassword(!showPassword);
                                    }}
                                >
                                    {showPassword ? (
                                        <ShowIcon className="h-[12.75px] w-[15px]" />
                                    ) : (
                                        <Hide className="h-[12.75px] w-[15px]" />
                                    )}
                                </span>
                            </label>
                            <NavLink
                                to="/forgot-password"
                                className="forgot-password"
                            >
                                Forgot Password?
                            </NavLink>
                        </div>

                        <div className="loginModal-container_captcha flex items-center justify-between">
                            <label className="flex items-center">
                                <input type="checkbox" />
                                <p>I'm not a robot</p>
                            </label>
                            <span>
                                <Recaptcha />
                            </span>
                        </div>

                        <button
                            className="loginModal-container_login flex items-center justify-center cursor-pointerjustify-center"
                            type="submit"
                        >
                            Log in{" "}
                        </button>
                    </form>
                </div>
            </Modal>
        </div>
    );
};

export default LoginModal;
