import React from 'react';
import { ReactComponent as Lock } from '../assets/lock.svg';

const Newsletter = () => {
  return (
      <div className='newsletter'>
        <div className='newsletter-container'>
            <p className='newsletter-title'>Join our newsletter</p>
            <p className="newsletter-subtitle">
                Enjoy discount and premium services from us and our partners before public adoption.
            </p>
            <div className='newsletter-input'>
                <label>
                    <input type="email" placeholder='Enter your email address' />
                </label>
                <button type="button" className='newsletter-input-subscribe'>
                    Subscribe
                </button>
            </div>
            <div className="flex justify-center items-center newsletter-secure">
                <Lock />
                <p className='newsletter-secure-text'>Your data is secured and will never be sold to any third party</p>
            </div>

            <div className='cloud'></div>
        </div>
      </div>
      
  )
};

export default Newsletter;
