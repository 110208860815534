import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
	mutation LoginUser($email: String!, $password: String!) {
		login(email: $email, password: $password) {
			token
			user {
				email
				id
				firstname
				lastname
				mobile
			}
		}
	}
`;
