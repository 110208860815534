import React from 'react';
import HeroDetail from './HeroDetail';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as Flash } from '../assets/flash.svg';

const Header = () => {
  return (
      <header>
        <div className='header-main'>
            <div className="hero-description-container ">
                <div className="logo-container flex items-center">
                    <div className="logo-container-frame ">
                        <Logo className='logo-container-frame_logo' />
                    </div>
                    <p className='logo-container-frame_text'>Blockchain smart flight refund and payments engine</p>
                </div>

                <div className='mb-[38px]'>
                    <p className='desc'>
                        Process your refund claims on the Aeropaye refund engine within seconds of delayed or cancelled flight.
                    </p>
                    <div className='flex flash-process'>
                        <Flash /> 
                        {/* <p className='process-refund'>
                            Process your refund claims on the Aeropaye refund engine within seconds of delayed or cancelled flight.
                        </p> */}
                    </div>
                </div>
            </div>

            <HeroDetail />
        
        </div>
        
           
            
    <div className="hero-features">
    </div> 
        
      </header>
  )
};

export default Header;
