const questions = [
    {
        id: 1,
        question: 'Why is aeropaye designed on Blockchain?',
        answer: 'Aeropaye is designed on Blockchain because of the security, and the tamper proof on all payment transactions initiated between travellers and Airlines.',
    },
    {
        id: 2,
        question: 'How do I get my refund when my flight is cancelled?',
        answer: 'You get your refund by just clicking the cancelled flight Button',
    },
    {
        id: 3,
        question: 'How long does it take to get my refund?',
        answer: 'The aeropaye infrastructure is designed to give travellers automated refund within seconds',
    },
    {
        id: 4,
        question: 'Can I use aeropaye as a travel agent?',
        answer: 'All online travel management companies or travel agents can create aeropaye account to help the unbanked travellers make payment',
    },
    {
        id: 5,
        question: 'Can I use aeropaye to Book flight?',
        answer: 'Yes you can use aeropaye to directly book flight and make payment',
    },
    {
        id: 6,
        question: 'Do you charge on the escrow smart Contract?',
        answer: 'Aeropaye does not charge on any smart contract initiated between travellers and Airlines',
    },
    {
        id: 7,
        question: 'Can I fund my Aeropaye account using Flutterwave, paypal, applepay, paystack and other payment Services?',
        answer: 'Yes aeropaye is an entirely new infrastructure designed on blockchain to give autonomous refund for any transaction used on the legacy payment services',
    },
    {
        id: 8,
        question: 'How do you cut Refund cost for Airlines?',
        answer: 'Airline do not have to worry about travellers refund claims and request, all the billing and settlement plan is automated on aeropaye for the airlines.',
    },
    {
        id: 9,
        question: 'Can I Pay for my ticket using aeropaye account on the airline website?',
        answer: 'Yes aeropaye is also API based for all Airlines.',
    },
    {
        id: 10,
        question: 'Can I have a refund request on delayed flight?',
        answer: 'Yes you can decide to cancel any delayed flight and also get automated refund.',
    },

]

export default questions