import React from 'react';
import Ticket from '../assets/ticket.svg';
import Refund from '../assets/refund.svg';
import Blockchain from '../assets/blockchain.svg';
import Discount from '../assets/discount.svg';


const Features = () => {
  return (
    <div className='container features mx-auto'>
        <p className='features-subtitle'>FEATURES</p>
        <p className="features-title">What makes us unique?</p>
        <p className='underline'></p>

        <div className="features-container">
            <div className='flex justify-center items-center md:flex-row flex-col container'>
                <div className='features-container_feature-1'>
                    <img src={Ticket} alt="ticket" />
                    <p className='features-container_feature-1-title'>
                        Tokenized ticketing system
                    </p>
                    <p className='features-container_feature-1-detail'>
                        Aeropaye convert a Tokenized Tickets digit into a digital credential that can't be stolen or reused.  With tokenized transactions, the personal Ticket is locked away for safekeeping, replaced with a unique digital identifier that can be limited to a specific device merchant or transaction type. And since Aeropaye Refund Engine can only validate it, a stolen tokenized Ticket is about as useful as a stolen car without an engine. It's going nowhere.
                    </p>
                </div>

                <div className='features-container_feature-2'>
                   <img src={Refund} alt="refund-engine" />
                    <p className='features-container_feature-2-title'>
                        Autonomous refund engine
                    </p>
                    <p className='features-container_feature-2-detail'>
                        The Autonomous Instant Refund Engine is applicable only when cancellation or delayed on your booked domestic or International flight from Airlines. Aeropaye Autonomous Refund Engine section on desktop or mobile. On cancelling or delaying the Flight, Aeropaye will process refund within seconds from the time of delayed or cancelled flight.
                    </p>
                </div>
            </div>

            <div className='flex justify-center items-center md:flex-row flex-col container'>
                <div className='features-container_feature-3'>
                   <img src={Blockchain} alt="blockchain" />
                    <p className='features-container_feature-3-title'>
                        Blockchain smart contract secured
                    </p>
                    <p className='features-container_feature-3-detail'>
                        Aeropaye Smart contracts improve the time-consuming processes in travellers refund claims adjudication to cut cost for Airlines and no more travellers filling documentation and chasing the travel fare fund.
                    </p>
                </div>

                <div className='features-container_feature-3'>
                   <img src={Discount} alt="discount" />
                    <p className='features-container_feature-3-title'>
                        Access to exclusive discounts
                    </p>
                    <p className='features-container_feature-3-detail'>
                        Aeropaye Offers exclusive discounts, Airlines and travellers will not only get traveller centric innovation and happy transportation for regional and international flights, but reaffirms the airline's commitment to Global destinations towards an open innovation via aeropaye automated Refund Engine.
                    </p>
                </div>

            </div>
        </div>
    </div>
    )
};

export default Features;
